import {  Form, Button, Toast, Spinner } from "react-bootstrap";
import Cookie  from "react-cookies";
import { useState, useContext, useEffect } from "react";
import {useParams, useLocation, Link, useNavigate } from 'react-router-dom'; 
import Alert from './Alert';
import DataContext from "./DataContext";
import MyButton from "./Button";
import {RegisterUser } from './API';
import Swal from "sweetalert2";

export const Register = () => {
    const [alert, setAlert] = useState(false)
    const [message, setMessage] = useState('');
    const [active, setActive] = useState(false)
    const [regInputs, setRegInputs] = useState('')
    const {getUser, setUser, activeUser, setActiveUser} = useContext(DataContext);
    const navigate = useNavigate()
    const [color, setColor] = useState('black');
    const style = { backgroundColor: 'white', borderRadius: '40px', color };



    const handleRegInput = (e) =>{
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setRegInputs( inputs => ({
          [name]: value,    }));
      }
      
      const handleRegister = async (e) => {
        e.preventDefault()
        setActive(true);
        const   password = e.target.password.value;
        //  console.log(password)
        if(e.target.phone.value.length !== 11){
            Swal.fire({
                text: "Phone number cannot be less or greater than 11 digits.",
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
            // setMessage(response.message)
            // setColor('red');
            // setAlert(true)
            setTimeout(() => {
                setActive(false)
            }, 1000)
        }else if( password === e.target.confirm.value){
            const data = {
                first_name: e.target.fname.value,
                last_name: e.target.lname.value,
                email: e.target.email.value,
                phone: e.target.phone.value,
                username: e.target.username.value,
                password
            }
            const response = await RegisterUser(data)
            console.log(response);
            if(response.success){
                setColor('black');
                // setMessage(response.message)
                setActiveUser(response);
                setUser('user', response);
                
                // setAlert(true)
                Swal.fire({
                    text: response.message,
                    icon: 'success',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
                setTimeout(() => {
                    setActive(false)
                    navigate('/dashboard')
                }, 2000)
                // console.log(response)
            } else{
                Swal.fire({
                    text: response.response.data.message ? response.response.data.message : response.message,
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
                // setMessage(response.message)
                // setColor('red');
                // setAlert(true)
                setTimeout(() => {
                    setActive(false)
                }, 1000)
                // console.log(response)
            }
            
        } else{
            Swal.fire({
                text: 'Password does not match Confirm Password',
                icon: 'warning',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
            // setMessage("Password does not match Confirm Password")
            // setColor('red');
            // setAlert(true)
            setTimeout(() => {
                setActive(false)
            }, 2000)
        
        }
    }
    useEffect(() => {
        document.title = "UXTECH | Register"
        console.log("register page")
    }, [])
    return (
        <div>
    <Form  method='post' onSubmit={handleRegister}>
    <Toast onClose={() => setAlert(false)} show={alert} delay={3000} autohide className='w-100'
        
        style={{ borderRadius: '40px', backgroundColor: 'white' }}
        >
            <Toast.Header className='  bg-light text-dark '  >
                  <small className='me-auto'>Message</small>
            </Toast.Header>

            <Toast.Body className= 'text-center '
            style={style}>
            <p><b> {message} </b></p> 
            </Toast.Body>
    </Toast>
                <Form.Group className="mb-1" controlId="">
                    <Form.Label >First Name</Form.Label>
                    <Form.Control type="text" 
                    placeholder="First Name"
                     name="fname"
                     required
                     />
                </Form.Group>
                <Form.Group className="mb-1" controlId="">
                    <Form.Label>Surname Name</Form.Label>
                    <Form.Control type="text" placeholder="Last Name" name="lname"
                    required
                    />
                </Form.Group>
                <Form.Group className="mb-1" controlId="formBasicEmail">
                    <Form.Label> Email </Form.Label>
                    <Form.Control type="email" placeholder="Enter email" name="email" 
                    required
                    />
                </Form.Group>
                <Form.Group className="mb-1" controlId="formBasicEmail">
                    <Form.Label> Phone </Form.Label>
                    <Form.Control type="number" placeholder="Enter Phone Number" name="phone" 
                    required
                    />
                </Form.Group>
                <Form.Group className="mb-1" controlId="">
                    <Form.Label> username</Form.Label>
                    <Form.Control type="text" placeholder="username" name="username"
                    required
                    />
                </Form.Group>

                <Form.Group className="mb-1" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" name="password"
                    required
                    />
                </Form.Group>
                <Form.Group className="mb-1" controlId="formBasicPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type="password" placeholder="Confirm Password" name='confirm'
                    required
                    />
                </Form.Group>
                <center>
                
                <MyButton name="Submit" active={active}/>
                </center>
    </Form>
        </div>
    )
}