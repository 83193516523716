import React from 'react'
import DataContext from './DataContext';
import { useContext, useEffect, useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';


export const Transfer = () => {
const {getUser} = useContext(DataContext);
const user = getUser('userData');
useEffect(() => {
  document.title = "UXTECH | User Dedicated Account Numbers"
}, [])
  return (
   <div className='row'>
    <h2 className='text-center'> Transfer </h2>
        {/* <div style={{ margin:'auto', backgroundColor: 'whitesmoke'}}> */}
        <small className='text-muted text-center'> The following account numbers are linked to your wallet.
         Any transfer to any of these accounts will credit your wallet automatically. </small>
    <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12'
    style={{ margin:'auto', marginTop: '5%'}}
    >
    {/* <div > */}
        
    
    <Form>
        <h5>{user.data.bank.split(',')[1]}</h5>
        <FormGroup >
        <FormGroup >
        <Form.Label> Bank Name:</Form.Label>
        <Form.Control type='text' readOnly value={user.data.bank.split(',')[1] }
        style={{ backgroundColor: 'black', color: 'white' }}
        />
        </FormGroup>
        <Form.Label> Acc. Name:</Form.Label>
        <Form.Control type='text' readOnly value={`UX-TECH-${user.data.accountName}`}
        style={{ backgroundColor: 'black', color: 'white' }}
        />
        </FormGroup>

        <FormGroup >
        <Form.Label> Acc. Number:</Form.Label>
        <Form.Control type='text' readOnly value={`${user.data.accountNumber.split(',')[1]}`}
        style={{ backgroundColor: 'black', color: 'white' }}
        />
        </FormGroup>

        
        <small className=" text-muted" 
                    style={{ color:'red', justifyItems: 'center'}}> 
                     50 flat rate charge for all amounts.
                     {/* <br/>
                    <small> 50 flat rate charge plus 1% for transfers from ₦4,001 to 1,000,000,000!</small>  */}
                    </small>

    </Form>
    {/* <h5>Bank: Wema Bank</h5>
    <h5>Acc Name: UX-TECH-{user.data.username}</h5>
    <h5>Account Number: {user.data.accountNumber}</h5> */}

    {/* </div> */}

    </div>
    {/* </div> */}
    <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12'
    style={{ margin:'auto', marginTop: '5%'}}
    >
    {/* <div > */}
        
    
    <Form>
      <h5>{user.data.bank.split(',')[0]}</h5>
        <FormGroup >
        <FormGroup >
        <Form.Label> Bank Name:</Form.Label>
        <Form.Control type='text' readOnly value={user.data.bank.split(',')[0] }
        style={{ backgroundColor: 'black', color: 'white' }}
        />
        </FormGroup>
        <Form.Label> Acc. Name:</Form.Label>
        <Form.Control type='text' readOnly value={`UX-TECH-${user.data.accountName}`}
        style={{ backgroundColor: 'black', color: 'white' }}
        />
        </FormGroup>

        <FormGroup >
        <Form.Label> Acc. Number:</Form.Label>
        <Form.Control type='text' readOnly value={`${user.data.accountNumber.split(',')[0]}`}
        style={{ backgroundColor: 'black', color: 'white' }}
        />
        </FormGroup>

        
        <small className=" text-muted" 
                    style={{ color:'red', justifyItems: 'center'}}> 
                     50 flat rate charge for all amounts.
                     {/* <br/>
                    <small> 50 flat rate charge plus 1% for transfers from ₦4,001 to 1,000,000,000!</small>  */}
                    </small>

    </Form>
    {/* <h5>Bank: Wema Bank</h5>
    <h5>Acc Name: UX-TECH-{user.data.username}</h5>
    <h5>Account Number: {user.data.accountNumber}</h5> */}

    {/* </div> */}

    </div>
    <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12'
    style={{ margin:'auto', marginTop: '5%'}}
    >
    {/* <div > */}
        
    
    <Form>
        <h4>{user.data.bank.split(',')[2]}</h4>
        <FormGroup >
        <FormGroup >
        <Form.Label> Bank Name:</Form.Label>
        <Form.Control type='text' readOnly value={user.data.bank.split(',')[2]}
        style={{ backgroundColor: 'black', color: 'white' }}
        />
        </FormGroup>
        <Form.Label> Acc. Name:</Form.Label>
        <Form.Control type='text' readOnly value={`UX-TECH-${user.data.accountName}`}
        style={{ backgroundColor: 'black', color: 'white' }}
        />
        </FormGroup>

        <FormGroup >
        <Form.Label> Acc. Number:</Form.Label>
        <Form.Control type='text' readOnly value={`${user.data.accountNumber.split(',')[2]}`}
        style={{ backgroundColor: 'black', color: 'white' }}
        />
        </FormGroup>

        
        <small className=" text-muted" 
                    style={{ color:'red', justifyItems: 'center'}}> 
                     50 flat rate charge for all amounts.
                     {/* <br/>
                    <small> 50 flat rate charge plus 1% for transfers from ₦4,001 to 1,000,000,000!</small>  */}
                    </small>

    </Form>
    {/* <h5>Bank: Wema Bank</h5>
    <h5>Acc Name: UX-TECH-{user.data.username}</h5>
    <h5>Account Number: {user.data.accountNumber}</h5> */}

    {/* </div> */}

    </div>
   </div>

  )
}
