import { Button, Container, Row, Col, Card, CardGroup, Navbar, Nav, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { useEffect, useState, useContext } from 'react';
import DataContext from './DataContext';
import {useNavigate, Link} from 'react-router-dom';
import Head from './Head';
import Cookie  from "react-cookies";
import {FaCoins, FaPeopleArrows, FaCreditCard, FaArrowRight, FaDollarSign} from 'react-icons/fa';
import {userData, testPayment} from './API';
import './style.css';
import { services } from './services';
import NewsAlert from './newsAlert';
import Swal from 'sweetalert2';
// console.log(services)

const Dashboard = () =>{
            
        const navigate = useNavigate();
        const {setUser, getUser, deleteUser, activeUser, setActiveUser, width} = useContext(DataContext);
        const [active, setActive] = useState(false)
        const type = ['Special', 'Regular', 'Agent', 'Premium'];
        const [status, setStatus] = useState(false);
        const user = getUser('user');
        const [show, setShow] = useState(false);
        const [color, setcolor] = useState('black');
        const [message, setMessage] = useState('');
        const style = { backgroundColor: 'white', borderRadius: '40px', color };
        const [position, setPosition] = useState(width < 600 ? 'bottom-center': 'top-end');
        const [w, setW] = useState(width < 600 ? 'w-100': 'w-75');
        // const [email, setEmail] = useState(null);

       

        useEffect(  () => {
            if(!user || user === 'undefined'){

                    navigate('/login?login')
            } else{
               const email = user.data.email;
                // setEmail(user.data[0].email);
                setStatus(true)
              
            const getUserData = async () => {
            const uData = await userData()
            // console.log(uData)
            if(uData.success){
                setActiveUser(uData)
                setUser('userData', uData);
                // console.log(uData);
            }else{
                console.log(uData);
                setMessage("User Session has Expired. Please Login again!")
                setcolor('red');
                setShow(true);
               setTimeout(()=> {
                deleteUser('user');
                navigate('/login?login')
               }, 4000);
            }
            
             }

            getUserData() 
         }
            // const uData = await userData()
            
        // console.log(user)
        }, [width])
// console.log(width)
        const logout = () => {
            // e.preventDefault()
            setActive(true)
            setTimeout(() => {
                deleteUser('user');
                deleteUser('userData');
                navigate('/');
            }, 3000)
        
        };
        // console.log(activeUser);
        // Cookie.remove('user')
        useEffect(() => {
            document.title = "UXTECH | Dashboard"
        }, [])

    return  !status ? ( <center> <h2>
      
        <Spinner className="d-flex mt-5"
        as="span"
        animation="border"
        size="lg"
        role="status"
        aria-hidden="true"
        />
        Loading... </h2></center>) 
        : (
                <div className="home" style={{ backgroundColor: '' }}> 
                <Head/>
                <Navbar collapseOnSelect expand  bg="light"  variant='light'  className='flex-column' >
            <Container >
            
                <Container className=''>
                
                <Navbar.Collapse id="responsive-navbar-nav" className='' >
                <Nav className="me-auto " id="navBox"   style={ { } }>
                <h1>  {`Hi, ${activeUser && 
                    activeUser.data && activeUser.data.name && (activeUser.data.name).split(" ")[0]}`}  </h1>
                </Nav>
            
                <Nav className="ms-auto " id="navBox"   style={ { } }>
                    <Nav.Link href='#'> 
                        <Button variant="outline-danger" disabled={active} onClick={logout}>
                            
                            {active && 
                            <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            />}
                            { !active ? <b> <FaArrowRight/> Logout </b>: <b>Loading...</b> }
                        </Button>
                    </Nav.Link>
                </Nav>
                
                </Navbar.Collapse>
                </Container>
                
            </Container>
            </Navbar>
            <NewsAlert user={activeUser && activeUser.data && activeUser.data}  />
        
                    
            <Container className=''  style={{  marginLeft: '', marginRight: ''  }}>
            <p className='text-muted me-auto' style={{ margin: 'auto' }}> status: {activeUser && type[activeUser.data.type]} </p>
            <p className='text-muted me-auto' style={{ margin: 'auto' }}>  </p>
               
            {/* Home Page Brief Message for Users. */}
            {/* <ToastContainer className='p-2 w-25' position='top-end'>
            <Toast onClose={() => setShow(false)} show={show} delay={10000} autohide className='w-100'
        
            style={{ borderRadius: '40px', backgroundColor: 'white' }}
            >
              <Toast.Header className='  bg-light text-dark '  >
                  <h4 className='me-auto'>Important Notice</h4>
              </Toast.Header>

              <Toast.Body className= 'text-center text-dark'
                  style={style}>
                    <p><b>  Click Add Fund To check your new Account Number that is linked to your wallet. </b></p>
              </Toast.Body>
        </Toast>
        </ToastContainer> */}
        <ToastContainer className={w} position={position}>
        <Toast onClose={() => setShow(false)} show={show} delay={6000} autohide className='w-100'
        
        style={{ borderRadius: '40px', backgroundColor:'white' }}
        >
          <Toast.Header className='  bg-light text-dark '  >
              <small className='me-auto'>Message</small>
          </Toast.Header>
          <Toast.Body className= 'text-center'
            style={style}>
                 <p><b> {message} </b></p> </Toast.Body>
        </Toast>
        </ToastContainer> 
            {/* <p className='text-muted me-auto' style={{ margin: 'auto' }}> Acc Name.: Wema Bank </p>
            <p className='text-muted me-auto' style={{ margin: 'auto' }}> Acc No.: {user.data[0].accountNumber} </p> */}
            
                <Button className='float-end' variant='dark' onClick={() => navigate('/fund')} >
                    <FaCreditCard size='30' /> 
                    <b>  Add Fund </b>
                </Button>
            </Container>


            <div>
                {/* Account History Start Here */}
            <Container> 
                <Row style={{ marginTop: '50px',  marginLeft: '5px', marginRight: '5px'  }}>
                    <Col md='4' sm='12' xs='12' lg='4' style={{ marginTop: '15px' }}>
                        <Card style={{  margin: 'auto', width: '' }} >
                            <Button variant='outline-dark' style={{ width: '', height: '80px' }}>
                            <h5>
                            <><FaCoins className='float-start' size='30' /></>
                                <Card.Title> <b> Wallet Balance </b> </Card.Title>
                            </h5>
                            <h3><p> ₦{activeUser && 
                            activeUser.data && activeUser.data.walletBallance}</p></h3>
                            </Button>
                        </Card>
                    </Col>
                    <Col md='4' sm='12' xs='12' lg='4' style={{ marginTop: '15px' }}>
                        <Card style={{  margin: 'auto'  }} >
                            <Button variant='outline-dark' style={{ width: '', height: '80px' }}>
                            <h5>
                            <><FaCoins className='float-start' size='30' /></>
                                <b> Total Earning </b> 
                            </h5>
                            <><b> Earnings are credited to wallet.</b></>
                            </Button>
                        </Card>
                    </Col>
                    <Col md='4' sm='12' xs='12' lg='4' style={{ marginTop: '15px' }}>
                        <Card style={{  margin: 'auto'  }} >
                            <Button variant='outline-dark' style={{ width: '', height: '80px' }} >
                            <h5>
                            <><FaPeopleArrows className='float-start' size='30' /></>
                                <b> Total Refer(s) </b> 
                            </h5>
                            <h3><p> {activeUser && activeUser.data && activeUser.data.refer || 0}</p></h3>
                            </Button>
                        </Card>
                    </Col>
                </Row>
            </Container>

                                           {/* Services Start Here */}
            <Container > 
             
                <Row style={{ marginTop: '10px',  marginLeft: '5px', marginRight: '5px', marginBottom: '20Px' }} variant='dark'>
                    
                        {/* <Card style={{  margin: 'auto'  }} >
                            <Button
                            
                            variant='outline-dark'
                            style={{ width: '', height: '140px' }}
                            onClick={() => navigate('/buyAirtime')}>
                            <img src={require('./image/a.png')} width='80vh' height='80' style={{ borderRadius: '40px' }} /> 
                            <><><p/><b>  Buy Airtime </b></></>
                            </Button> */}
                 { services.map((service, index) => {
                     return(
                    <Col md='3' sm='6' xs='6' lg='3' style={{ marginTop: '15px' }} key={index}>
                        <Card style={{  margin: 'auto', marginBottom: '5px'  }}  >
                             <Button 
                            
                                    variant='outline-dark'
                                    style={{ width: '', height: '140px' }}
                                    onClick={() => {
                                        if(service.location !== '#') {
                                            navigate(service.location)
                                        }else if(service.ref === 'refer'){
                                            Swal.fire({
                                                title: "Refer Friends",
                                                html:`<b>Your referal link is 👇🏽</b><br/> ${'https://www.uxtech.ng/login?'+user.data.username}`,
                                                confirmButtonColor: 'black'
                                            })
                                        }else{
                                            // setMessage('This service is currently not available.');
                                            // setShow(true);
                                            Swal.fire({
                                                title: "Coming Soon!",
                                                html:`<p>This service is not available at the moment.</p>`,
                                                confirmButtonColor: 'black'
                                            })
                                        }
                                        }}
                                    >
                                    {
                                        // Service Image
                                        service.type === 'transfer' ? (
                                        //   <FaDollarSign size={20} />
                                          <span className='imageIconContainer'
                                        //   style={{ 
                                        //     borderRadius: '100%',
                                        //      backgroundColor: 'black',
                                        //       width: '100px',
                                        //     textAlign: 'center'
                                        //     }}
                                          >
                                            <h4 className='imageIcon'
                                            // style={}
                                            >₦</h4 >
                                          </span>
                                          
                                        ):
                                        (
                                            <img src={service.image} width='60px' height='60' style={{ borderRadius: '100px' , margin: 'auto' }} />
                                        )
                                    }
                                    <p/><b> {service.name} </b>
                            </Button>    
                        </Card>
                    </Col>
                       
                                )
                                // <img src={require('./image/a.png')} width='80vh' height='80' style={{ borderRadius: '40px' }} />
                            }) }
                        {/* </Card> */}
                    
                </Row>
            </Container>
           
            </div>
                


                </div>
            )
} 

export default Dashboard