import { FormGroup, Form, Button, ButtonGroup , Row, Col, Container, Spinner} from "react-bootstrap";
import Cookie  from "react-cookies";
import { useState, useContext } from "react";
import {useParams, useLocation, Link, useNavigate, useSearchParams, } from 'react-router-dom'; 
import {login} from './API';
import Alert from './Alert';
import DataContext from "./DataContext";
import MyButton from "./Button";
import { useEffect } from "react";
import  {Login} from "./Signin";
import { Register } from "./Signup";


const MyForm = () =>{
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    
    const [active, setActive] = useState(false)
    const { setUser, setActiveUser} = useContext(DataContext)
    const [input, setInput] = useState({});

    const state = useLocation().search.substr(1);
    const [request, setRequest] = useState(state === 'login'?'login': 'register')
    const [status, setStatus] = useState(state === 'login'? true : false)
   
    // console.log(state)
    // setStatus(state);
    // console.log(useLocation('search'))
    // console.log(state)

    // let query = useQuery()
    //  const name = useSearchParams()[0]
    //  console.log(name)
    // console.log(useParams())
    // console.log(useLocation())
    // console.log(useSearchParams())
return (
    <div>
        
    
    <Row style={{  marginTop: '40px' }}>
        
    <center><img src={require("./image/ux.png")}
         alt="UX-ELITE TECHNOLGIES"
          width='200'
          style={{ 
            margin: 'auto', 
            marginBottom: '20px',
            height: '50px' 
        }}
          />
          </center>
        <Col xs='10' lg='4' sm='8' md='6'  style={{ margin:'auto', backgroundColor: 'whitesmoke', borderRadius: '20px'}}>
        
        <div  style={{ height: '50px', backgroundColor: 'whitesmoke',  borderRadius: '50px' }}>
            < >
            <Button className="float-start w-50" 
            style={{ borderRadius: '50px' }} 
             size='lg'
              onClick={() => {
                setRequest('login')
                setStatus(true)
                
                }} variant='outline-dark' active={status}>
                    Login 
                </Button>

            <Button className="float-end w-50"
             style={{ borderRadius: '50px' }} 
            size="lg" 
            onClick={() => {
                setRequest('Register')
                setStatus(false)
                
                }} variant='outline-dark' active={!status}>Register
                 </Button>  
                 </>
     </div>  
     {/* <h4 
     className="bg-white text-dark text-center"
     > UNDER MAINTENACE, PLEASE CHECK BACK LATER </h4> */}
      
            
            {request === 'login'? 
            <Login/> : <Register/>
            }
        </Col>
    
    </Row>
     </div>
)

}

export default MyForm;